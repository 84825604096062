// Screenshots

.screenshots {
	display: block;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;

	> * {
		display: block;
		margin: 0 0 15px 0;
	}

	a {
		display: block;
	}

	img {
		display: block;
		@extend .sunset-border;
		border-radius: 2px;

		@include media-breakpoint-up(md){
			width: 160px;
			margin-right: 15px;
		}
	}
}

.feature-heading {
	pointer-events: none;
}

@mixin feature-image-variant($border-color, $glow-color) {
	border: 2px solid $border-color;
	box-shadow: 0 0 27px rgba($glow-color, 35%);
}

.feature-image {
	@include feature-image-variant($white, $white);

	@include media-breakpoint-down(sm){
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.section--blue & {
		@include feature-image-variant(#64ceff, #0084ff);
	}

	.section--pink & {
		@include feature-image-variant(#ff79e1, #ff0090);
	}

	.section--orange & {
		@include feature-image-variant(#ffa879, #ff7521);
	}
}

.feature-gif {
	max-width: 646px;
}

// Relevant Gyfcat embed styles
.media-container .media {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.media-container {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    z-index: 0;
	line-height: 0;
	padding: 0;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}