$lightbox-animate-scale: false;

.lightbox {
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
	opacity: 0;
	transition: opacity .2s;

	&.in {
		opacity: 1;
		pointer-events: all;
	}
}

.lightbox-bg {
	z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: black;
	opacity: 0.8;
	// opacity: 0;
	// transition: opacity .2s;

	// .in & {
	// 	opacity: 0.8;
	// }
}

.lightbox-container {
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	overflow: auto;

	@if $lightbox-animate-scale {
		transform: scale(0.9);
		transition: transform .2s;

		.in & {
			transform: scale(1);
		}
	}
}

.lightbox-content {

}

.lightbox-video {
	width: 1280px;
	max-width: 100%;
	transition: width .3s;

	@media (min-width: 2100px) and (min-height: 1200px){
		width: 1920px;
	}
}