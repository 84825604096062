.section-header {
	// min-height: 100vh;
	// background: url(../img/header-bg.png) no-repeat center;
	color: white;
	position: relative;
	padding: 0;
	background: #0a040e;

	p {
		@include media-breakpoint-down(md){
			margin-bottom: 0;
		}
	}
}

.header-bottom,
.header-top {
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
}

.header-top {
	padding-top: to-rem(100px);

	@include media-breakpoint-down(sm){
		padding-top: to-rem(40px);
	}
}

.header-bottom {
	padding-top: 4rem;
	padding-bottom: to-rem(90px);

	@include media-breakpoint-down(xs){
		padding-top: 2rem;
		padding-bottom: to-rem(60px);
	}
}


@include media-breakpoint-down(md){
	.logo-bg {
		display: none;
	}
}

.header-logo-spacer {
	position: relative;

	// @include media-breakpoint-down(sm){
	// 	order: -1;
	// }
}



// @include media-breakpoint-up(lg){
	// .header-logo-spacer {
	// 	height: percentage(420/1080);
	// 	flex-shrink: 0;
	// 	flex-grow: 0;
	// 	background: blue;
	// }

	// .header-top {
	// 	height: percentage(340/1080);
	// }

	// .logo-inline {
	// 	display: none;
	// }

	.header-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;		
	}

	.header-gradient {
		background: linear-gradient(to bottom, rgba(#120012, 0.6) 0%, rgba(#120012, 0) 25%, rgba(#120012, 0) 63%, rgba(#120012, 0.6) 100%);
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
// }

.learn-more {
	background: url(../img/learn-more-arrow.png) no-repeat center bottom;
	margin-top: auto;
	padding-top: 0;

	&:hover,
	&:focus {
		// color: $body-font-color;
		color: white;
	}

	// @include media-breakpoint-down(xl){
		padding-bottom: 30px;
	// }

	@include media-breakpoint-down(md){
		padding-bottom: 36px;
	}
}

.header-logo {
	$image-height: 906px;
	$image-width: 1785px;
	margin-top: percentage(-200px / $image-width); // margin percentages are relative to element width
	margin-bottom: percentage(-230px / $image-width);
	width: $image-width;
	max-width: 100%;

	.header-logo-placeholder {
		height: 0;
		padding-bottom: percentage($image-height / $image-width);
	}

	.header-logo-img {
		animation: logo-entrance .8s .3s backwards cubic-bezier(0.000, 0.835, 0.250, 1.000);
	}
}

.fade-in {
	animation: fade-in .5s 0.2s backwards;
}

.enter-after {
	opacity: 0;

	.loaded & {
		opacity: 1;
	}
}



.enter-after-logo-1 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.0s backwards;
	}
}

.enter-after-logo-2 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.1s backwards;
	}
}

.enter-after-logo-3 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.15s backwards;
	}
}

.enter-after-logo-4 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.2s backwards;
	}
}

.enter-after-logo-5 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.25s backwards;
	}
}

.enter-after-logo-6 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.30s backwards;
	}
}

.enter-after-logo-7 {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.35s backwards;
	}
}

.enter-after-logo-later {
	@extend .enter-after;
	.loaded & {
		animation: fade-in .5s 1.5s backwards;
	}
}

@keyframes logo-entrance {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	// 50% {
	// 	opacity: 1;
	// 	transform: scale(0.5);
	// }

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.98);
	}
}

.coming-date-wrapper {
	@include media-breakpoint-down(sm) {
		margin-left: -30px;
		margin-right: -30px;
	}
}

.coming-date {
	margin: -80px 0 -30px;
	// animation: pulse 0.6s ease-in-out infinite alternate;

	@include media-breakpoint-down(sm) {
		margin-left: 0;
		margin-right: 0;
		margin-top: -40px;
	}
}

.platform-logo {
	display: block;
	transition: transform 2s;
	margin: -70px -67px -70px -70px; // --80px;
	transform: scale(0.95);
	opacity: 0.9;

	img {
		// 80px worth of glow
		
		display: block;
	}

	&:hover {
		// transform: scale(1);
		opacity: 1;

		img {
			// filter: grayscale(100%) brightness(300%); // white
			filter: hue-rotate(-10deg) brightness(110%) saturate(150%); // purple
		}
	}

	&:active {
		transform: scale(0.95);
		transition: transform .1s;
	}
}

.platform-logo-wrapper {
	margin: 5px 4vw;
	text-align: center;
	
	@include media-breakpoint-up(md) {
		margin-left: 50px;
		margin-right: 50px;
	}

	@include media-breakpoint-down(sm) {
		margin-left: 0;
		margin-right: 0;
		// width: 100%;
	}
}

.platform-logos {
	transform-origin: top center;

	@include media-breakpoint-up(sm) {
		margin-bottom: -30px; 
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;

		// Looks awkward if logos are larger than the date
		transform: scale(0.8);
		
		margin-bottom: -80px;
	}
}