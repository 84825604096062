// Typography
// --------------------------------------------------------------------------------------------

html {
	font-size: 16px;
}

b {
	color: #bb92f9;
}

.thin {
	font-weight: 300;
}

.hero {
	@include media-breakpoint-up(xl){
		font-size: 22px;
	}
}

.subtitle {
	@extend .lead;
	padding-top: 50px;
	padding-bottom: 50px;
	color: white;

	@include media-breakpoint-down(sm){
		padding-top: 20px;
	}
}

.lead {
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 0.5em;
	font-weight: 300;
	color: white;


	&.has-button {
		line-height: 54px;
		.button {
			margin-top: -2px;
		}
	}

	@include media-breakpoint-down(sm){
		font-size: 15px;
		// -webkit-font-smoothing: antialiased;
	}
}

.text-sunset {
	color: #fd6e5a;
	
	.backgroundcliptext & {
		-webkit-font-smoothing: antialiased;
		background: linear-gradient(to right, #fd6e5a, #f35292);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.backgroundcliptext .text-sunset--inline {
	font-weight: normal;
}

h1.text-sunset,
h2.text-sunset,
h3.text-sunset,
h4.text-sunset {
	display: inline-block;
}

a.text-sunset {
	&:hover,
	&:focus {
		color: #f35292;

		@include media-breakpoint-up(md){
			.backgroundcliptext & {
				background: linear-gradient(to right, darken(#fd6e5a, 10%), darken(#f35292, 10%));
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}
}

h3 {
	margin-bottom: 30px;
}

.text-subtle {
	opacity: 0.6;
}

.blog {
	color: lighten($body-color, 10%);
	
	h1, h2, h3, h4 {
		color: white;
	}
}