
.wrapper {
	max-width: 1920px;
	min-height: 100vh;
	margin: 0 auto;
	position: relative;
	background: $body-bg url(../img/scanlines-black-purple.png) repeat;
}


// .input-group .input-group-field:not(:last-child){
// 	border-right: none;
// }


.summary-text {
	font-weight: 300;

	// p {
	// 	line-height: 1.4;
	// }
}

.summary-text--large {
	@include media-breakpoint-up(md){
		font-size: to-rem(22px);
	}
}

.summary-container {
	max-width: 100%;
	// margin-bottom: 3rem;
}

.summary-container--medium {
	width: 800px;
}

.summary-container--small {
	width: 450px;
}

.summary-container--center {
	margin-left: auto;
	margin-right: auto;	
}

.eighties-inspired {
	$image-width: 1035px;
	$top-spacing: 90px;
	margin-top: -($top-spacing / $image-width) * 100%;
	// margin-bottom: 30px;
	pointer-events: none;
}

.section-powerups {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	@include media-breakpoint-up(xl){
		min-height: 850px;
	}
}

.stackable-powerups {
	// margin-top: 70px;
	margin-bottom: 20px;
}

.fighting-spirit {
	background: url(../img/fighting-spirit-8bit.png) no-repeat left;
}

.hero-perk {
	position: relative;
	margin-bottom: -$section-padding;
	transition: opacity 2s;

	@include media-breakpoint-up(md){
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: flex-end;
		overflow: hidden;
		margin: 0;

		@include media-breakpoint-only(md){
			opacity: 0.5;
		}

		&.left {
			left: 0;
			right: 50%;
			justify-content: flex-end;
		}

		&.right {
			left: 50%;
			right: 0;
		}

		img {
			min-height: 100%;
			width: auto;
			height: auto;
			display: block;
			max-width: none;
			max-height: 100%;
			position: absolute; // needs to be absolute to workaround a bug in iOS where the image gets stretched to fit in the width
		}

		// @include media-breakpoint-only(sm){
		// 	position: static;
		// 	overflow: visible;
		// 	display: block;
		// 	width: 100%;
		// }
	}
}

ul.attribution-list {
	&,
	ul {
		padding-left: 1.2rem;
	}

	li {
		opacity: 0.8;
		transition: opacity .4s;
	}

	li:hover {
		opacity: 1;
		transition: none;
	}
}