// Video

.video-bg-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: 1;
	pointer-events: none;

	video {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: auto;
		min-height: 100%;
		min-width: 100%;
		background: transparent;
	}
}

.video-bg-pattern {
	background: rgba(0, 0, 0, 0.4) url(../img/video-pattern-grid-black.png) repeat;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	opacity: 0.5;
}

.video-bg-content {
	position: relative;
	z-index: 2;
}

.video-fallback {
	display: none;
}

.video-bg {
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	// right: 0;
	// bottom: 0;
	left: 0;
	overflow: hidden;
}

.grid-video-wrapper {
	width: 100%;
	height: 100%;
	top: auto;
	bottom: 0;
	position: absolute;


	@include media-breakpoint-down(sm){
		// height: 70%;
	}

	.grid-video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// object-fit: contain;
		object-position: top center;
		object-fit: cover;
		background-size: cover;
	}

	
	.no-objectfit &,
	.platform-android & {
		background: url(/img/grid-1080.jpg) no-repeat top center;
		background-size: cover;
		
		.grid-video {
			display: none;
		}
	}
	

	// @include media-breakpoint-down(md){
		
		
	// }

	
}

// .no-objectfit {
// 	.grid-video {
// 		display: none;
// 	}
// }